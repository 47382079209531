<template>
  <div class="form-group">
    <div style="display: flex" class="position-relative">
      <label
        v-if="item.label"
        :class="
          item.class_div
            ? 'form-label ' + item.class_lable
            : 'text-xs mb-2 text-typo font-weight-bolder ms-1 ' +
              item.class_lable
        "
        >{{ item.label }}</label
      >
      <span v-if="item.req" class="text-danger astrik mr-2">*</span>
      <slot name="label-section"></slot>
    </div>
    <div :class="item.class_div" class="position-relative">
      <v-checkbox
        v-if="item.type == 'checkbox'"
        :rules="item.rules"
        :color="item.color"
        :label="item.label_input"
        :value="item.value"
        :disabled="item.disabled"
        :error-messages="item.error"
        v-model="item.value"
      ></v-checkbox>

      <v-file-input
        v-else-if="item.type == 'file'"
        :value="value"
        :multiple="
          item.type_file && item.type_file == 'multiple' ? true : false
        "
        v-model="item.value"
        :outlined="
          item.class && item.class.indexOf('form-control') > -1 ? true : false
        "
        :class="item.class"
        :rules="item.rules"
        :accept="item.accept"
        show-size
        :error-messages="item.error"
        :disabled="item.disabled"
        :chips="item.chips"
        @change="$emit('change', $event)"
        @click:clear="$emit('clear', $event)"
        @input="updateChangeFiles($event)"
      ></v-file-input>

      <input
        v-else-if="item.type == 'hidden'"
        type="hidden"
        :value="item.value"
      />
      <v-select
        v-else-if="item.type == 'select'"
        :outlined="
          item.class && item.class.indexOf('form-control') > -1 ? true : false
        "
        dense
        lazy-validation
        :items="item.items"
        :rules="item.rules"
        :dense="item.class ? true : false"
        :item-text="item.title_select ? item.title_select : 'name'"
        :item-value="item.val_select ? item.val_select : 'id'"
        :label="$t('general.choose')"
        :error-messages="item.error"
        persistent-hin
        single-line
        v-model="item.value"
        :return-object="item.object ? true : false"
        :multiple="
          item.type_select && item.type_select == 'multiple' ? true : false
        "
        :class="item.class"
        @change="$emit('input', $event)"
        :value="item.value ? item.value : value"
        :disabled="item.disabled"
      ></v-select>

      <template v-else-if="item.type == 'autocomplete'">
        <v-autocomplete
          dense
          v-model="item.value"
          :return-object="item.return_object ? item.return_object : false"
          lazy-validation
          :rules="item.rules"
          :items="item.items"
          :item-text="item.title_select ? item.title_select : 'name'"
          :item-value="'id'"
          :placeholder="$t('general.choose')"
          :error-messages="item.error"
          :multiple="item.type_select == 'multiple' ? true : false"
          :disabled="item.disabled"
          @change="$emit('input', $event)"
          :value="item.value ? item.value : value"
          :outlined="
            item.class && item.class.indexOf('form-control') > -1 ? true : false
          "
        ></v-autocomplete>
        <!-- <span v-if="item.rules && item.value == null" class="text-danfer">{{
          $t("form.Item is required")
        }}</span> -->
      </template>

      <v-textarea
        v-else-if="item.type == 'textarea'"
        :label="item.label"
        auto-grow
        :outlined="item.class ? true : false"
        :rows="item.row"
        row-height="15"
        :error-messages="item.error"
        :rules="item.rules"
        :disabled="item.disabled"
        @input="updateValue"
        @keyup="KeyUpData($event, item)"
        v-model="item.value"
        :value="item.value ? item.value : value"
      ></v-textarea>

      <!-- <input
        class="form-control"
        :value="item.value"
        :type="item.type"
        :placeholder="item.placeholder"
        @input="updateValue($event.target.value)"
        @blur="updatekeyValue($event.target.value)"
        v-else
      /> -->
      <template v-else-if="item.type == 'password'">
        <v-text-field
          @input="updateValue"
          :append-icon="item.show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="item.show ? 'text' : 'password'"
          @click:append="item.show = !item.show"
          v-model="item.value"
          :rules="item.rules"
          lazy-validation
          color="rgba(0,0,0,.6)"
          light
          :error-messages="item.error"
          :value="item.value ? item.value : value"
          :placeholder="item.placeholder"
          :class="item.class"
          :outlined="item.class ? true : false"
          class="font-size-input placeholder-lighter text-light-input"
        >
        </v-text-field>
      </template>

      <html-editor
        v-else-if="item.type == 'editor'"
        row="20"
        class="white-space-pre"
        v-model="item.value"
        :value="item.value ? item.value : value"
      ></html-editor>

      <v-text-field
        v-else-if="item.type == 'datetime'"
        @input="updateValue"
        :rules="item.rules"
        :class="item.class"
        type="datetime-local"
        :error-messages="item.error"
        color="rgba(0,0,0,.6)"
        :min="item.min"
        :placeholder="item.placeholder"
        class="font-size-input placeholder-lighter text-light-input"
        :disabled="item.disabled"
        v-model="item.value"
        :value="item.value ? item.value : value"
        :outlined="item.class ? true : false"
      >
      </v-text-field>
      <v-text-field
        v-else
        @input="updateValue"
        @keyup="KeyUpData($event, item)"
        :rules="item.rules"
        :class="item.class"
        :type="item.type ? item.type : 'text'"
        :error-messages="item.error"
        color="rgba(0,0,0,.6)"
        :min="item.min"
        :placeholder="item.placeholder"
        class="font-size-input placeholder-lighter text-light-input pt-0"
        :disabled="item.disabled"
        v-model="item.value"
        :value="item.value ? item.value : value"
        :outlined="
          item.class && item.class.indexOf('form-control') > -1 ? true : false
        "
      >
      </v-text-field>
      <slot name="feild"></slot>
    </div>
  </div>
</template>
<script>
import HtmlEditor from "../Components/HtmlEditor.vue";
export default {
  name: "Input",
  props: ["item", "value", "show"],
  data() {
    return {
      menu: false,
    };
  },
  components: {
    HtmlEditor,
  },
  methods: {
    updateValue: function (value) {
      this.$emit("input", value);
    },
    KeyUpData: function ($event, value) {
      this.$emit("keyupdata", { value: $event.target.value, item: value });
      // KeyUpData: function ($event) {
      // this.$emit("keyupdata", $event.target.value);
    },
    updatekeyValue: function (value) {
      this.$emit("blur", value);
    },
    updateChangeFile: function (value) {
      this.$emit("change", value);
    },
    updateChangeFiles: function (value) {
      this.$emit("input", value);
    },
    // clear(){

    // }
  },
};
</script>
